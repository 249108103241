<template>
  <b-card>
    <!-- Alert: No item found -->
    <b-alert variant="danger" v-if="facility === null">
      <h4 class="alert-heading">Error fetching user data</h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link class="alert-link" :to="{ name: 'admin-facilities' }">
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>
    <b-row v-else>
      <!-- User Info: Left col -->
      <b-col
        cols="12"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex flex-wrap">
          <b-avatar
            :text="avatarText(facility.name)"
            :src="facility.avatar"
            variant="danger"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1 top-level">
            <div class="mb-1 text-center">
              <h4 class="mb-0">
                {{ facility.name }}
              </h4>
              <p class="card-text">{{ facility.email }}</p>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                v-if="[1,2].includes(+userRol.id)"
                :to="{
                  name: 'admin-facilities-edit',
                  params: { id: facility.id },
                }"
                variant="primary"
              >
                Edit
              </b-button>
              <!-- <b-button variant="outline-danger" class="ml-1">
                Delete
              </b-button> -->
            </div>
          </div>
        </div>

        <!-- User Stats -->
        <div class="d-flex align-items-center mt-2">
          <div class="d-flex align-items-center mr-2">
            <b-avatar variant="light-primary" rounded>
              <i class="fas fa-dollar-sign"></i>
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">23.3k</h5>
              <small>Monthly Sales</small>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <b-avatar variant="light-success" rounded>
              <i class="fas fa-chart-bar"></i>
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">$99.87k</h5>
              <small>Annual Profit</small>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col cols="12" xl="6">
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <i class="far fa-address-card mr-2"></i>
              <span class="font-weight-bold">Name</span>
            </th>
            <td class="pb-50">{{ facility.name }} {{ facility.lastname }}</td>
          </tr>
          <tr>
            <th class="pb-50">
              <i class="fas fa-city mr-2"></i>
              <span class="font-weight-bold">City</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ facility.cities.name }} - {{ facility.cities.states.name }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <i class="far fa-calendar-alt mr-2"></i>
              <span class="font-weight-bold">Attention Schedule</span>
            </th>
            <td class="pb-50 text-capitalize">
              Mon - Sat: {{ JSON.parse(facility.attention_schedule).mon_to_sat }} <br>
              Sunday: {{ JSON.parse(facility.attention_schedule).sunday }} 
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <i class="fas fa-street-view mr-2"></i>
              <span class="font-weight-bold">Address</span>
            </th>
            <td class="pb-50">
              {{ facility.adress }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <i class="fas fa-phone mr-2"></i>
              <span class="font-weight-bold">Phone</span>
            </th>
            <td class="pb-50">
              {{ facility.phone }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <i class="fas fa-phone-volume mr-2"></i>
              <span class="font-weight-bold">Pbx</span>
            </th>
            <td class="pb-50">
              {{ facility.pbx }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>

    <slot></slot>
  </b-card>
</template>

<script>
import {
  BAlert,
  BLink,
  BCard,
  BButton,
  BAvatar,
  BRow,
  BCol,
} from "bootstrap-vue";
import { avatarText } from "@/core/utils/filter";

import axiosF from "@/core/services/api/admin/facilities";

export default {
  components: {
    BAlert,
    BLink,
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
  },
  data() {
    return {
      facility: null,
      userRol: JSON.parse(localStorage.getItem("userData")).roles[0]
    };
  },
  mounted() {
    this.getFacility();
  },
  methods: {
    avatarText(name) {
      return avatarText(name);
    },
    getFacility() {
      axiosF
        .facilityDetail(this.$route.params.id)
        .then(({registro}) => {
          this.facility = registro;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 506px) {
  .top-level {
    margin-top: 1rem;
    align-items: center;
  }
}
</style>  